import { useRef } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerAchievementCardElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_element_shown';
import { employerAchievementCardTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_tooltip_element_shown';
import { DreamjobSize16, DreamjobSize24 } from '@hh.ru/magritte-ui/product';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BadgeContainer from 'src/components/Employer/BadgeContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    openEmployer: 'employer.sidebar.openEmployer.description',
};

const IconSizeMap = {
    small: DreamjobSize16,
    medium: DreamjobSize24,
} as const;

interface OpenEmployerIconProps {
    size: 'small' | 'medium';
}

const OpenEmployerIcon: TranslatedComponent<OpenEmployerIconProps> = ({ size, trls }) => {
    const isTrusted = useSelector((state) => state.employerInfo.isTrusted);
    const isOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const employerId = useSelector((state) => state.employerInfo.id);
    const activatorRef = useRef(null);
    if (!isTrusted || !isOpenEmployer) {
        return null;
    }
    const IconComponent = IconSizeMap[size];
    return (
        <HoverTip
            onHover={(show) =>
                show &&
                employerAchievementCardTooltipElementShownRaw({
                    employerId: String(employerId),
                    achievementType: 'transparency',
                })
            }
            render={() => trls[TrlKeys.openEmployer]}
            placement={TipPlacement.Top}
            layer={TipLayer.Overlay}
            host={!process.env.SSR ? document.body : null}
            activatorRef={activatorRef}
        >
            <span ref={activatorRef} data-qa="open-employer-icon">
                <ElementShownAnchor
                    fn={employerAchievementCardElementShown}
                    employerId={String(employerId)}
                    achievementType="transparency"
                    Element="span"
                >
                    <BadgeContainer size={size} icon={<IconComponent initialColor="accent" />} />
                </ElementShownAnchor>
            </span>
        </HoverTip>
    );
};

export default translation(OpenEmployerIcon);
