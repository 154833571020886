import { Fragment, useCallback } from 'react';

import {
    ActionBar,
    BottomSheet,
    Modal,
    Button,
    Card,
    Text,
    VSpacing,
    NavigationBar,
    Action,
    BottomSheetFooter,
} from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize24, ChevronLeftOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useIsRabotaBy } from 'src/hooks/useSites';

import styles from './styles.less';

interface ImageCropAdviceModalProps {
    onModalClose: () => void;
    onUpload: () => void;
    onBack?: () => void;
    onRemoveLogo: () => void;
    visible: boolean;
    canRemoveLogo: boolean;
    shouldShowBack: boolean;
}

const TrlKeys = {
    upload: 'employer.logoEdit.modal.upload',
    remove: 'employer.logoEdit.modal.remove',
    back: 'employer.logoEdit.modal.back',
    title: 'employer.logoEdit.modal.advice.title',
    formats: 'employer.logoEdit.modal.advice.formats',
    theme: 'employer.logoEdit.modal.advice.theme',
    background: 'employer.logoEdit.modal.advice.background',
    details: 'employer.logoEdit.modal.advice.details',
};

const ImageCropAdviceModal: TranslatedComponent<ImageCropAdviceModalProps> = ({
    trls,
    onModalClose,
    onUpload,
    onBack,
    onRemoveLogo,
    visible,
    canRemoveLogo,
    shouldShowBack,
}) => {
    const isZP = useIsZarplataPlatform();
    const isRabotaBy = useIsRabotaBy();

    const siteLogo = useCallback(() => {
        if (isZP) {
            return <div className={styles.zarplataLogo} />;
        }

        if (isRabotaBy) {
            return <div className={styles.rabotaByLogo} />;
        }

        return <div className={styles.hhLogo} />;
    }, [isZP, isRabotaBy]);

    const renderContent = () => {
        return (
            <>
                <Card style="secondary" padding={24} borderRadius={12}>
                    <Text typography="label-1-regular">{trls[TrlKeys.formats]}</Text>
                </Card>
                <VSpacing default={40} />
                <Text typography="label-1-regular">{trls[TrlKeys.theme]}</Text>
                <VSpacing default={16} />
                <Card borderWidth="default" padding={24} borderRadius={12} stretched>
                    <div className={styles.logosContainer}>
                        {siteLogo()}
                        <ArrowRightOutlinedSize24 />
                        <Card borderWidth="default" padding={8} borderRadius={12}>
                            {siteLogo()}
                        </Card>
                        <Card style="constant-secondary" padding={8} borderRadius={12}>
                            {siteLogo()}
                        </Card>
                    </div>
                </Card>
                <VSpacing default={40} />
                <Text typography="label-1-regular">{trls[TrlKeys.background]}</Text>
                <VSpacing default={40} />
                <Card borderWidth="default" padding={24} borderRadius={12} stretched>
                    <div className={styles.logosContainer}>
                        <div className={styles.setkaLogoTransparent} />
                        <ArrowRightOutlinedSize24 />
                        <Card style="constant-secondary" padding={8} borderRadius={12}>
                            <div className={styles.setkaLogo} />
                        </Card>
                    </div>
                </Card>
                <VSpacing default={64} />
                <Text typography="label-1-regular">{trls[TrlKeys.details]}</Text>
                <VSpacing default={40} />
                <Card borderWidth="default" padding={24} borderRadius={12} stretched>
                    <div className={styles.logosContainer}>
                        <Card style="constant-secondary" padding={8} borderRadius={12}>
                            <div className={styles.skillazLogo} />
                        </Card>
                        <Card style="constant-secondary" padding={8} borderRadius={12}>
                            <div className={styles.skillazAltLogo} />
                        </Card>
                    </div>
                </Card>
            </>
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                onClose={onModalClose}
                actions={<Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />}
                title={trls[TrlKeys.title]}
                size="large"
                footer={
                    <ActionBar
                        primaryActions={[
                            <Fragment key={trls[TrlKeys.back]}>
                                {shouldShowBack && (
                                    <Button size="medium" style="accent" mode="tertiary" onClick={onBack}>
                                        {trls[TrlKeys.back]}
                                    </Button>
                                )}
                            </Fragment>,
                            <Button
                                key={trls[TrlKeys.upload]}
                                size="medium"
                                style="accent"
                                mode="primary"
                                onClick={onUpload}
                            >
                                {trls[TrlKeys.upload]}
                            </Button>,
                        ]}
                        secondaryActions={
                            !!canRemoveLogo && (
                                <Button size="medium" style="negative" mode="tertiary" onClick={onRemoveLogo}>
                                    {trls[TrlKeys.remove]}
                                </Button>
                            )
                        }
                    />
                }
            >
                {renderContent()}
            </Modal>
            <BottomSheet
                header={
                    <NavigationBar
                        left={
                            shouldShowBack ? (
                                <Action icon={ChevronLeftOutlinedSize24} onClick={() => onBack?.()} />
                            ) : undefined
                        }
                        title={trls[TrlKeys.title]}
                    />
                }
                height="full-screen"
                visible={visible}
                onClose={onModalClose}
                footer={
                    <BottomSheetFooter>
                        <Button mode="primary" style="accent" onClick={onUpload} size="large">
                            {trls[TrlKeys.upload]}
                        </Button>
                        {!!canRemoveLogo && (
                            <Button style="negative" mode="tertiary" onClick={onRemoveLogo} size="large">
                                {trls[TrlKeys.remove]}
                            </Button>
                        )}
                    </BottomSheetFooter>
                }
            >
                {renderContent()}
                <VSpacing default={24} />
            </BottomSheet>
        </>
    );
};

export default translation(ImageCropAdviceModal);
