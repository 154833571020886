import { useCallback } from 'react';
import Dropzone, { DropzoneRenderFunction, GetInputPropsFn } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import sidebarButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/sidebar_button_click';
import sidebarElementShown from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/sidebar_element_shown';
import employerUploadLogoFormSubmit from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/employer_upload_logo_form_submit';
import { TrashOutlinedSize16, CameraOutlinedSize16, CameraOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button from 'bloko/blocks/button';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatNewLine } from 'bloko/common/trl';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import {
    employerLogoFileTooLarge,
    employerLogoUnsupportedFileFormat,
} from 'src/components/Notifications/EmployerSidebar';
import Snackbar from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Status } from 'src/models/employer/employerLogo';

import deleteLogo from 'src/components/Employer/Sidebar/deleteLogo';
import uploadLogo from 'src/components/Employer/Sidebar/uploadLogo';

interface LogoEditProps {
    invertDefaultText?: boolean;
}

const TrlKeys = {
    addLogo: 'employer.edit.sidebar.addLogo',
    logoParams: 'employer.edit.sidebar.logoParams',
};

const LogoEdit: TranslatedComponent<LogoEditProps> = ({ invertDefaultText, trls }) => {
    const employerLogoEditSettings = useSelectorNonNullable((state) => state.employerLogoEditSettings);
    const { logoUrl, logoStatus } = useSelector((state) => state.employerLogo);

    const employerId = useSelector((state) => state.employerInfo.id || 0);
    const hasConstructorService = useSelector((state) => state.employerConstructor.hasService);

    const loading = logoStatus === Status.Fetching;

    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const onLoad = useCallback(
        (accepted: File[], isDragNDrop = true) => {
            if (loading) {
                return;
            }
            const file = accepted[0];

            const analyticsParams = {
                actionType: logoUrl ? 'create' : 'edit',
                employerId,
                employerPageConstructorIsActive: hasConstructorService,
                loadType: isDragNDrop ? 'drag-n-drop' : 'button',
                filename: file.name,
                fileSizeKb: file.size / 1024,
                fileHeight: 0,
                fileWidth: 0,
                fileType: file.type,
            } as const;

            if (file.size > employerLogoEditSettings.maxSizeBytes) {
                addNotification(Snackbar, employerLogoFileTooLarge);
                employerUploadLogoFormSubmit({ ...analyticsParams, errors: 'EMPLOYER_LOGO_FILE_TOO_LARGE' });
                return;
            }

            if (!employerLogoEditSettings.allowedMimeTypes.includes(file.type)) {
                addNotification(Snackbar, employerLogoUnsupportedFileFormat);
                employerUploadLogoFormSubmit({
                    ...analyticsParams,
                    errors: 'EMPLOYER_LOGO_UNSUPPORTED_FILE_FORMAT',
                });
                return;
            }

            dispatch(uploadLogo({ file, analyticsParams }, addNotification, () => {})).catch(console.error);
        },
        [
            loading,
            employerLogoEditSettings.maxSizeBytes,
            employerLogoEditSettings.allowedMimeTypes,
            dispatch,
            logoUrl,
            addNotification,
            employerId,
            hasConstructorService,
        ]
    );

    const renderInput = useCallback(
        (getInputProps: GetInputPropsFn) => (
            <input
                {...getInputProps()}
                type="file"
                className="g-hidden"
                accept={employerLogoEditSettings.allowedMimeTypes.join()}
                onChange={(event) => {
                    const imageFile = event.target.files?.[0];
                    event.target.value = '';
                    if (imageFile) {
                        onLoad([imageFile], false);
                    }
                }}
                data-qa="sidebar-upload-logo"
            />
        ),
        [employerLogoEditSettings.allowedMimeTypes, onLoad]
    );

    const renderImageDropZone: DropzoneRenderFunction = useCallback(
        ({ getRootProps, getInputProps, isDragActive }) => {
            if (!logoUrl) {
                return (
                    <div
                        {...getRootProps()}
                        className={classnames('employer-sidebar-logo-load employer-sidebar-logo-load_no-image', {
                            'employer-sidebar-logo-load_drop': isDragActive,
                        })}
                    >
                        <div className="employer-sidebar-logo-load__icon">
                            {loading ? (
                                <Loading data-qa="logo-loader" scale={LoadingScale.Medium} />
                            ) : (
                                <BlokoIconReplaceContainer>
                                    <CameraOutlinedSize24 initialColor={invertDefaultText ? 'contrast' : 'secondary'} />
                                </BlokoIconReplaceContainer>
                            )}
                        </div>
                        <ElementShownAnchor
                            fn={sidebarElementShown}
                            elementName="change_logo"
                            className="employer-sidebar-logo-load__add-link"
                        >
                            <Link
                                appearance={LinkAppearance.Pseudo}
                                onClick={() => sidebarButtonClick({ buttonName: 'change_logo' })}
                            >
                                {trls[TrlKeys.addLogo]}
                                {renderInput(getInputProps)}
                            </Link>
                        </ElementShownAnchor>
                        <div
                            className={classnames('employer-sidebar-logo-load__description', {
                                'employer-sidebar-logo-load__description_inverted': invertDefaultText,
                            })}
                        >
                            <Text>{formatNewLine(trls[TrlKeys.logoParams])}</Text>
                        </div>
                    </div>
                );
            }
            return (
                <div
                    {...getRootProps()}
                    className={classnames('employer-sidebar-logo-load', {
                        'employer-sidebar-logo-load_drop': isDragActive,
                    })}
                >
                    <div className="employer-sidebar-logo-load__button">
                        <label>
                            <Button
                                Element="span"
                                icon={
                                    <BlokoIconReplaceContainer>
                                        <CameraOutlinedSize16 />
                                    </BlokoIconReplaceContainer>
                                }
                            />
                            {renderInput(getInputProps)}
                        </label>
                    </div>
                    <div className="employer-sidebar-logo-load__button">
                        <Button
                            onClick={() => dispatch(deleteLogo(addNotification))}
                            icon={
                                <BlokoIconReplaceContainer>
                                    <TrashOutlinedSize16 />
                                </BlokoIconReplaceContainer>
                            }
                        />
                    </div>
                    {loading && (
                        <div className="employer-sidebar-logo-load__icon employer-sidebar-logo-load__icon_container">
                            <Loading scale={LoadingScale.Medium} />
                        </div>
                    )}
                </div>
            );
        },
        [addNotification, dispatch, invertDefaultText, loading, logoUrl, renderInput, trls]
    );

    return (
        <Dropzone multiple={false} disableClick={!!logoUrl} onDrop={(accepted) => onLoad(accepted, true)}>
            {renderImageDropZone}
        </Dropzone>
    );
};

export default translation(LogoEdit);
