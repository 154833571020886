import { FC, PointerEventHandler } from 'react';
import classnames from 'classnames';

import { Layer } from '@hh.ru/magritte-ui';

import { DraggedPointType, PointType } from 'src/components/ImageCropPopup/Points/pointType';

import styles from './dragable-point.less';

interface DragablePointProps {
    type: DraggedPointType;
    onPointerDown: PointerEventHandler<HTMLDivElement>;
    pointType: PointType;
}

const styleMap = {
    [PointType.Circle]: {
        [DraggedPointType.LeftTop]: styles.circlePointLeftTop,
        [DraggedPointType.RightTop]: styles.circlePointRightTop,
        [DraggedPointType.RightBottom]: styles.circlePointRightBottom,
        [DraggedPointType.LeftBottom]: styles.circlePointLeftBottom,
    },
    [PointType.Corner]: {
        [DraggedPointType.LeftTop]: styles.cornerPointLeftTop,
        [DraggedPointType.RightTop]: styles.cornerPointRightTop,
        [DraggedPointType.RightBottom]: styles.cornerPointRightBottom,
        [DraggedPointType.LeftBottom]: styles.cornerPointLeftBottom,
    },
};

const DragablePoint: FC<DragablePointProps> = ({ type, onPointerDown, pointType }) => {
    return (
        <Layer layer="alpha">
            <div
                onPointerDown={onPointerDown}
                className={classnames(styles[`${pointType}Point`], styleMap[pointType][type])}
            />
        </Layer>
    );
};

export default DragablePoint;
