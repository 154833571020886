import { useCallback, useRef } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerAutosearchElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/autosearch/employer_autosearch_element_shown';
import { Button, ButtonSize, TooltipHover } from '@hh.ru/magritte-ui';
import { CheckOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SubscribeButton from 'src/components/Employer/Subscribe';
import { useSubscribeContext } from 'src/components/Employer/Subscribe/SubscribeContext';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    employerViewVacanciesSubscribe: 'employer.view.sidebar.button.vacanciesSubscribe',
    menuItemSubscribe: 'employerView.header.subscribe.menuItem.subscribe',
    success: 'save-search-employer-autosearch-link',
    subscribedAlready: 'employerView.header.subscribe.already',
    subscribedAlreadyTooltip: 'employerView.header.subscribe.already.tooltip',
};

interface SubscribeProps {
    stretched?: boolean;
    isMenuItem?: boolean;
    subscribeButtonText?: string;
    size?: ButtonSize;
}

const Subscribe: TranslatedComponent<SubscribeProps> = ({
    stretched,
    subscribeButtonText,
    trls,
    size = 'small',
    isMenuItem = false,
}) => {
    const { count, isSubscribeAllowed } = useSubscribeContext();
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);
    const activatorRef = useRef(null);
    const employerInfo = useSelector(({ employerInfo }) => employerInfo);
    const { id: employerId } = employerInfo;
    const showIcon = isMagritteEmployerPageHeaderExp && !!count;

    const getButtonText = useCallback(() => {
        if (count) {
            if (isMagritteEmployerPageHeaderExp) {
                return trls[TrlKeys.subscribedAlready];
            }
            return trls[TrlKeys.success];
        }
        if (isMenuItem) {
            return trls[TrlKeys.menuItemSubscribe];
        }
        return subscribeButtonText || trls[TrlKeys.employerViewVacanciesSubscribe];
    }, [count, isMagritteEmployerPageHeaderExp, isMenuItem, subscribeButtonText, trls]);

    if (!isSubscribeAllowed) {
        return null;
    }

    return (
        <>
            {showIcon && (
                <TooltipHover placement="bottom-center" size="small" activatorRef={activatorRef}>
                    {trls[TrlKeys.subscribedAlreadyTooltip]}
                </TooltipHover>
            )}
            <ElementShownAnchor
                fn={employerAutosearchElementShown}
                elementName={count ? 'employer_autosearch_link' : 'employer_autosearch_subscribe'}
                employerId={String(employerId)}
            >
                <Button
                    ref={activatorRef}
                    Element={SubscribeButton}
                    size={size}
                    style="neutral"
                    mode="secondary"
                    stretched={stretched}
                    icon={showIcon ? <CheckOutlinedSize16 /> : undefined}
                    data-qa={count ? 'vacancy-serp__search-saved' : 'employer__search-saved'}
                >
                    {getButtonText()}
                </Button>
            </ElementShownAnchor>
        </>
    );
};

export default translation(Subscribe);
