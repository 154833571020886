import { Fragment, createElement, ReactNode } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import { CheckCircleOutlinedSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { formatNewLine } from 'bloko/common/trl';

import translation from 'src/components/translation';

const TrlKeys = {
    fileTooLarge: 'employer.logo.upload.notify.fileTooLarge',
    fileSizeTooSmall: 'employer.logo.upload.notify.fileTooSmall',
    unsupportedFileFormat: 'employer.logo.upload.notify.unsupportedFileFormat',
    fileTooLargeMobile: 'employer.logo.upload.notify.fileTooLarge.mobile',
    unsupportedFileFormatMobile: 'employer.logo.upload.notify.unsupportedFileFormat.mobile',
    uploadSuccess: 'employer.logo.upload.notify.uploadSuccess',
    uploadTimeout: 'employer.logo.upload.notify.uploadTimeout',
    uploadError: 'employer.logo.upload.notify.uploadError',
    autoSearchSavedSuccessText: 'vacancySearchResults.saveSearch.success.text',
    autoSearchSavedSuccessTitle: 'vacancySearchResults.saveSearch.success.title',
    autoSearchSavedApplicantTitle: 'serp.savedSearch.success.1',
    autoSearchSavedApplicantTeaser: 'serp.savedSearch.teaser.autosearch',
    autoSearchSavedApplicantMessage: 'serp.savedSearch.success.2.vacancy',
    autoSearchTooManyErrorsTitle: 'employer.sidebar.subscribe.tooMany.title',
    autoSearchTooManyErrorsMessage: 'employer.sidebar.subscribe.tooMany.message',
    autoSearchTooManyErrorsTeaser: 'employer.sidebar.subscribe.tooMany.teaser',
};

const FileTooLarge: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();

    return formatNewLine(trls[isMobile ? TrlKeys.fileTooLargeMobile : TrlKeys.fileTooLarge]);
};
export const employerLogoFileTooLarge = {
    props: {
        children: createElement(translation(FileTooLarge)),
        showClose: true,
        addon: <MinusCircleFilledSize24 initialColor="negative" />,
    },
};

const UnsupportedFileFormat: TranslatedComponent = ({ trls }) => {
    const { isMobile } = useBreakpoint();

    return formatNewLine(trls[isMobile ? TrlKeys.unsupportedFileFormatMobile : TrlKeys.unsupportedFileFormat]);
};
export const employerLogoUnsupportedFileFormat = {
    props: {
        children: createElement(translation(UnsupportedFileFormat)),
        showClose: true,
        addon: <MinusCircleFilledSize24 initialColor="negative" />,
    },
};

export interface FileSizeTooSmallProps {
    logoMinWidth: number;
    logoMinHeight: number;
}
const FileSizeTooSmall: TranslatedComponent<FileSizeTooSmallProps> = ({ trls, logoMinWidth, logoMinHeight }) => {
    const minWidthFormatted = numberFormatter.format(String(logoMinWidth));
    const minHeightFormatted = numberFormatter.format(String(logoMinHeight));
    return <>{format(trls[TrlKeys.fileSizeTooSmall], { '{0}': minWidthFormatted, '{1}': minHeightFormatted })}</>;
};
export const employerLogoFileSizeTooSmall = ({
    logoMinWidth,
    logoMinHeight,
}: FileSizeTooSmallProps): {
    props: {
        children: ReactNode;
        showClose: boolean;
        autoCloseDelay: number;
        addon: JSX.Element;
    };
} => ({
    props: {
        children: createElement(translation(FileSizeTooSmall), { logoMinWidth, logoMinHeight }),
        showClose: true,
        autoCloseDelay: 10000,
        addon: <MinusCircleFilledSize24 initialColor="negative" />,
    },
});

const UploadSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.uploadSuccess]}</>;
export const employerLogoUploadSuccess = {
    props: {
        children: createElement(translation(UploadSuccess)),
        showClose: true,
        addon: <CheckCircleOutlinedSize24 initialColor="positive" />,
    },
};

const UploadTimeout: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.uploadTimeout]}</>;
export const employerLogoUploadTimeout = {
    props: {
        children: createElement(translation(UploadTimeout)),
        showClose: true,
        addon: <MinusCircleFilledSize24 initialColor="negative" />,
    },
};

export interface EmployerLogoUploadErrorProps {
    logoMaxWidth: number;
    logoMaxHeight: number;
}
const UploadError: TranslatedComponent<EmployerLogoUploadErrorProps> = ({ trls, logoMaxWidth, logoMaxHeight }) => {
    const maxWidthFormatted = numberFormatter.format(String(logoMaxWidth));
    const maxHeightFormatted = numberFormatter.format(String(logoMaxHeight));
    return <>{format(trls[TrlKeys.uploadError], { '{0}': maxWidthFormatted, '{1}': maxHeightFormatted })}</>;
};
export const employerLogoUploadError = ({
    logoMaxWidth,
    logoMaxHeight,
}: EmployerLogoUploadErrorProps): {
    props: {
        children: ReactNode;
        showClose: boolean;
        autoCloseDelay: number;
        addon: JSX.Element;
    };
} => ({
    props: {
        children: createElement(translation(UploadError), { logoMaxWidth, logoMaxHeight }),
        showClose: true,
        autoCloseDelay: 10000,
        addon: <MinusCircleFilledSize24 initialColor="negative" />,
    },
});

const AutosearchSaved: TranslatedComponent = ({ trls }) => {
    return (
        <Fragment>
            <NotificationHeading>{trls[TrlKeys.autoSearchSavedSuccessTitle]}</NotificationHeading>
            <div>{trls[TrlKeys.autoSearchSavedSuccessText]}</div>
        </Fragment>
    );
};
export const employerSidebarAutosearchSaved = {
    Element: translation(AutosearchSaved),
    kind: 'ok',
    autoClose: true,
};

const AutosearchSavedApplicant: TranslatedComponent = ({ trls }) => {
    return (
        <Fragment>
            <NotificationHeading>
                {trls[TrlKeys.autoSearchSavedApplicantTitle]}{' '}
                <BlokoLink disableVisited Element={SPALink} to="/applicant/autosearch">
                    {trls[TrlKeys.autoSearchSavedApplicantTeaser]}
                </BlokoLink>
            </NotificationHeading>
            <div>{trls[TrlKeys.autoSearchSavedApplicantMessage]}</div>
        </Fragment>
    );
};
export const employerSidebarAutosearchSavedApplicant = {
    Element: translation(AutosearchSavedApplicant),
    kind: 'ok',
    autoClose: true,
};

const AutosearchTooManyError: TranslatedComponent = ({ trls }) => (
    <Fragment>
        <NotificationHeading>{trls[TrlKeys.autoSearchTooManyErrorsTitle]}</NotificationHeading>
        <div>
            {trls[TrlKeys.autoSearchTooManyErrorsMessage]}{' '}
            <BlokoLink disableVisited Element={SPALink} to="/applicant/autosearch">
                {trls[TrlKeys.autoSearchTooManyErrorsTeaser]}
            </BlokoLink>
        </div>
    </Fragment>
);
export const employerSidebarAutosearchTooMany = {
    Element: translation(AutosearchTooManyError),
    kind: 'error',
    autoClose: true,
};
