import { FC } from 'react';
import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import verificationButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/verification_button_click';
import verificationElementShown from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/verification_element_shown';
import HSpacing from 'bloko/blocks/hSpacing';
import { H1 } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import ItAccreditation from 'src/components/Employer/ItAccreditation';
import OrganizationForm from 'src/components/Employer/OrganizationForm';
import TrustedEmployerCheck, { TrustedEmployerCheckSize } from 'src/components/Employer/TrustedEmployerCheck';
import EmployerBadgesIconWrapper from 'src/components/EmployerBadges/EmployerBadgesIconWrapper';
import { useSelector } from 'src/hooks/useSelector';

interface HeaderTitleProps {
    /** Инвертировать цвет */
    inverted?: boolean;
    isMainHeader?: boolean;
}

const HeaderTitle: FC<HeaderTitleProps> = ({ inverted, isMainHeader }) => {
    const companyName = useSelector((state) => state.employerInfo.name);
    const organizationFormId = useSelector((state) => state.employerInfo.organizationFormId);
    const category = useSelector((state) => state.employerInfo.category);
    const isTrusted = useSelector((state) => state.employerInfo.isTrusted);
    const isAccreditedITEmployer = useSelector((state) => state.employerInfo.accreditedITEmployer);
    const employerCategories = useSelector((state) => state.employerCategories);

    const currentCategory = employerCategories.find((item) => item.code.toLowerCase() === category);

    return (
        <div>
            {currentCategory && (
                <div className={classnames({ 'company-type-inverted': inverted })}>
                    <Text>{currentCategory.translation}</Text>
                </div>
            )}
            <div className="company-header-title-name-container">
                <H1 Element={isMainHeader ? 'h1' : 'span'}>
                    <span
                        className={classnames({
                            'company-header-title-name-inverted': inverted,
                        })}
                        data-qa="company-header-title-name"
                    >
                        <OrganizationForm organizationFormId={organizationFormId} />
                        {companyName}{' '}
                    </span>
                </H1>
                <HSpacing base={2} />
                <EmployerBadgesIconWrapper>
                    {isTrusted && (
                        <ElementShownAnchor fn={verificationElementShown}>
                            <TrustedEmployerCheck
                                size={TrustedEmployerCheckSize.Large}
                                onClick={() => verificationButtonClick()}
                            />
                        </ElementShownAnchor>
                    )}
                    {isAccreditedITEmployer && <ItAccreditation isInverted={inverted} isHighlighted />}
                </EmployerBadgesIconWrapper>
            </div>
            <VSpacing base={4} xs={2} />
        </div>
    );
};

export default HeaderTitle;
