import { Fragment, useRef } from 'react';
import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import sidebarButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/sidebar_button_click';
import sidebarElementShown from '@hh.ru/analytics-js-events/build/xhh/common/branding/employer_page/sidebar_element_shown';
import { VSpacing, Avatar } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import FormItem from 'bloko/blocks/form/FormItem';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import CdnImg from 'src/components/CdnImg';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import HeaderTitle from 'src/components/Employer/HeaderTitle';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import translation from 'src/components/translation';
import { useCdnImageHost } from 'src/hooks/useCdnImageHost';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import CircleLogoEdit from 'src/components/Employer/Sidebar/CircleLogoEdit';
import Content from 'src/components/Employer/Sidebar/Content';
import LogoEdit from 'src/components/Employer/Sidebar/LogoEdit';
import Subscribe from 'src/components/Employer/Sidebar/Subscribe';
import WantWorkHere from 'src/components/Employer/Sidebar/WantWorkHere';

import styles from './styles.less';

const TrlKeys = {
    toConstructorLink: 'employer.edit.simple.sidebar.button.constructor',
    toPaidConstructorLink: 'employer.edit.simple.sidebar.button.constructor.paid',
    toConstructorLinkTooltip: 'employer.edit.simple.sidebar.button.constructor.tooltip',
    editSidebar: 'employer.edit.sidebar.editSidebar',
    constructorGuide: 'employer.edit.sidebar.constructorGuide',
    constructorGuideLink: 'employer.edit.sidebar.constructorGuideLink',
    wantWorkHereText: 'employer.wantWorkHere.text',
    wantWorkHereCountResumesText: 'employer.wantWorkHere.textCountResumes',
    resumeCount: {
        1: 'employer.wantWorkHere.countResumes.one',
        2: 'employer.wantWorkHere.countResumes.two',
        3: 'employer.wantWorkHere.countResumes.three',
        4: 'employer.wantWorkHere.countResumes.four',
        5: 'employer.wantWorkHere.countResumes.five',
    },
};

interface SidebarProps {
    withoutColors?: boolean;
    editMode?: boolean;
    hideTitle?: boolean;
    showConstructorButton?: boolean;
    showConstructorGuideLink?: boolean;
    hideEditSidebarButton?: boolean;
}

const Features = {
    enableCircleEmployerLogoUploader: 'enable_circle_employer_logo_uploader',
};

const Sidebar: TranslatedComponent<SidebarProps> = ({
    trls,
    editMode,
    hideTitle,
    showConstructorButton,
    showConstructorGuideLink,
    hideEditSidebarButton,
    withoutColors,
}) => {
    const logoUrl = useSelector((state) => state.employerLogo.logoUrl);
    const fullPathlogoUrl = useCdnImageHost(logoUrl || '');
    const employerId = useSelector((state) => state.employerInfo.id);
    const companyName = useSelector((state) => state.employerInfo.name);
    const accepted = useSelector((state) => state.employerInfo.accepted);
    const buttons = useSelector((state) => state.employerButtons);
    const hasConstructorService = useSelector((state) => state.employerConstructor.hasService);
    const hasEditAccess = useSelector((state) => state.hasEditSidebarButton);
    const previousEmployerTargets = useSelector((state) => state.employerInfo.previousEmployerTargets);
    const sidebarColors = useSelector((state) => state.employerConstructor.sidebarColors);

    const isCircleEmployerLogoUploaderEnabled = useFeatureEnabled(Features.enableCircleEmployerLogoUploader);
    const colors = !withoutColors ? sidebarColors : undefined;

    const showLogoContainer = editMode || logoUrl;

    const containerStyle = colors?.background ? { backgroundColor: `#${colors.background}` } : {};
    const isChangedBackground = !!colors?.background;
    const headerStyle = isChangedBackground && colors?.header ? { color: `#${colors.header}` } : {};
    const linkStyle = isChangedBackground && colors?.link ? { color: `#${colors.link}` } : {};

    const constructorLink = '/employer/constructor?from=sidebar&hhtmFromLabel=sidebar';
    const isZarplata = useIsZarplataPlatform();
    const tipActivatorRef = useRef(null);

    const previousTargetIds = previousEmployerTargets?.map(({ id }) => id);
    const previousTargetIdsUnique = [...new Set(previousTargetIds)];
    const resumesInfo = useSelector((state) => state.applicantInfo);
    const visibleResumesCount = resumesInfo.visible;
    let countResumes = previousTargetIdsUnique.length;
    if (countResumes > visibleResumesCount) {
        // если у пользователя есть скрытые резюме
        countResumes = visibleResumesCount;
    }

    const trlCountResumes = TrlKeys.resumeCount[countResumes as keyof typeof TrlKeys.resumeCount];
    const textCountResumes = trlCountResumes ? trls[trlCountResumes] : countResumes.toString();

    const infoTextWantWorkHere = (
        <>
            {countResumes > 0 && (
                <Text strong>
                    <span className={styles.wantWorkHereText} style={headerStyle}>
                        {trls[TrlKeys.wantWorkHereText]}
                    </span>
                </Text>
            )}

            {countResumes > 1 && (
                <SPALink
                    to={`/applicant/wantwork/payment?employerId=${
                        employerId || ''
                    }&from=employer_sidebar&hhtmFromLabel=employer_sidebar`}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore Type 'Element' is not assignable to type 'undefined'.
                    style={linkStyle}
                >
                    {format(trls[TrlKeys.wantWorkHereCountResumesText], {
                        '{0}': textCountResumes,
                    })}
                </SPALink>
            )}
            {countResumes > 0 && <VSpacing default={24} />}
        </>
    );

    const wantWorkHereBlock = buttons.workHere && (
        <>
            {infoTextWantWorkHere}
            {(visibleResumesCount !== countResumes || visibleResumesCount === 0) && (
                <div className="employer-sidebar-button">
                    <WantWorkHere stretched />
                </div>
            )}
        </>
    );

    const constructorButtonName = hasConstructorService && !isZarplata ? 'to_constructor' : 'brand_me';

    const forEdit = () => (
        <Fragment>
            {hasEditAccess && !hideEditSidebarButton && (
                <FormItem>
                    <ElementShownAnchor fn={sidebarElementShown} elementName="edit">
                        <Button
                            Element={SPALink}
                            to="/employer/edit/sidebar"
                            data-qa="edit-sidebar-button"
                            stretched
                            onClick={() => sidebarButtonClick({ buttonName: 'edit' })}
                        >
                            {trls[TrlKeys.editSidebar]}
                        </Button>
                    </ElementShownAnchor>
                </FormItem>
            )}
            {showConstructorGuideLink && (
                <FormItem>
                    <ElementShownAnchor
                        fn={sidebarElementShown}
                        elementName="instruction"
                        className="employer-sidebar-constructor-guide-link"
                    >
                        <BlokoLink
                            disableVisited
                            Element={SPALink}
                            to={trls[TrlKeys.constructorGuideLink]}
                            target="_blank"
                            onClick={() => sidebarButtonClick({ buttonName: 'instruction' })}
                        >
                            {trls[TrlKeys.constructorGuide]}
                        </BlokoLink>
                    </ElementShownAnchor>
                </FormItem>
            )}
            {showConstructorButton && (
                <ElementShownAnchor fn={sidebarElementShown} elementName={constructorButtonName}>
                    <FormItem>
                        <HoverTip
                            host={!process.env.SSR ? document.body : null}
                            render={() => trls[TrlKeys.toConstructorLinkTooltip]}
                            activatorRef={tipActivatorRef}
                        >
                            <Button
                                Element={SPALink}
                                to={constructorLink}
                                stretched
                                kind={ButtonKind.Primary}
                                onClick={() =>
                                    sidebarButtonClick({
                                        buttonName: constructorButtonName,
                                    })
                                }
                                innerRef={tipActivatorRef}
                            >
                                {hasConstructorService && !isZarplata
                                    ? trls[TrlKeys.toPaidConstructorLink]
                                    : trls[TrlKeys.toConstructorLink]}
                            </Button>
                        </HoverTip>
                    </FormItem>
                </ElementShownAnchor>
            )}
        </Fragment>
    );

    const forPreview = () => (
        <Fragment>
            {wantWorkHereBlock}
            {!!buttons.saveSearch && (
                <div className="employer-sidebar-button">
                    <Subscribe stretched />
                </div>
            )}
            {!!buttons.complain && (
                <div className="employer-sidebar-button">
                    <ContainerForMicroFrontend place="employerComplain" />
                </div>
            )}
        </Fragment>
    );

    let invertDefaultText = false;

    if (colors?.background) {
        const rgb = colors.background.match(/.{2}/gi)?.map((value) => parseInt(value, 16)) || [];
        // переводим RGB палитру в HSV, нам оттуда нужен только V - светлость цвета
        const hsV = Math.max(...rgb) / 255;
        invertDefaultText = hsV < 0.65;
    }

    const currentBreakpoint = useBreakpoint();
    const isSmallScreen = [Breakpoint.XS, Breakpoint.S].includes(currentBreakpoint);
    const isXs = currentBreakpoint === Breakpoint.XS;
    const showHeaderReviewsWidget = accepted && isSmallScreen;

    const renderLogoContainer = () => {
        if (!showLogoContainer) {
            return null;
        }

        if (isCircleEmployerLogoUploaderEnabled) {
            return (
                <>
                    {editMode ? (
                        <div className={styles.logoContainer}>
                            <div className={styles.logoEditContainer} data-qa="logo-edit-container">
                                <Avatar
                                    mode={logoUrl ? 'image' : 'placeholder'}
                                    image={fullPathlogoUrl}
                                    fallbackMode="placeholder"
                                    placeholder="city"
                                    shapeCircle
                                    size={96}
                                    aria-label="Employer Logo"
                                    data-qa="company-logo-image"
                                />
                                <CircleLogoEdit />
                            </div>
                        </div>
                    ) : (
                        <div className="employer-sidebar__logo-container">
                            {logoUrl && (
                                <div className="employer-sidebar__logo-img-container">
                                    <CdnImg
                                        loading="lazy"
                                        className="employer-sidebar__logo"
                                        path={logoUrl}
                                        alt={companyName}
                                        data-qa="company-logo-image"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </>
            );
        }

        return (
            <div className="employer-sidebar__logo-container">
                {logoUrl && (
                    <div className="employer-sidebar__logo-img-container">
                        <CdnImg
                            loading="lazy"
                            className="employer-sidebar__logo"
                            path={logoUrl}
                            alt={companyName}
                            data-qa="company-logo-image"
                        />
                    </div>
                )}
                {editMode && <LogoEdit invertDefaultText={invertDefaultText} />}
            </div>
        );
    };

    return (
        <div className="employer-sidebar-wrapper" data-qa="sidebar-background-color" style={containerStyle}>
            {isXs && <div className="employer-sidebar-header">{renderLogoContainer()}</div>}
            <div
                className={classnames('employer-sidebar-header', {
                    'employer-sidebar-header_hide-title-on-xs': hideTitle,
                })}
            >
                <HeaderTitle inverted={invertDefaultText} isMainHeader={isSmallScreen} />
                {showHeaderReviewsWidget && <EmployerReviewsWidgetSmall />}
            </div>
            <div className="employer-sidebar" style={containerStyle}>
                {!isXs && renderLogoContainer()}
                <Content withoutLogo={!editMode ? !logoUrl : false} colors={colors} />
                <div className="employer-sidebar__footer">
                    <VSpacing default={24} />
                    {editMode ? forEdit() : forPreview()}
                </div>
            </div>
        </div>
    );
};

export default translation(Sidebar);
